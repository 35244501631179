<template>

  <div class="content relative content-wrapper w-full bg-gradient-to-b from-blue-25 to-yellow-25 overflow-hidden px-16 py-16">
    <section class="relative z-50 max-w-4xl mx-auto bg-white rounded-xl shadow-xl p-16">
      <div class="grid grid-cols-2 gap-16">
        <div class="col-auto">
          <div class="space-y-12">
            <h1 class="text-3xl font-extrabold">
              Bejelentkezés
            </h1>
            <div class="text-lg font-light">
              Sikeres bejelentkezés után megtekintheted a rendeléseid státuszát és minden olyan információt, ami a lorem ipsum dolor sit amet.
            </div>
          </div>
        </div>
        <div class="col-auto">
          <form class="space-y-8" v-on:submit.prevent="login" method="post">
            <div class="space-y-8 text-md">
              <input type="text" v-model="email" class="w-full px-6 py-4 bg-gray-200 rounded-lg shadow-inner text-md" required placeholder="E-mail cím">
              <input type="password" v-model="password" class="w-full px-6 py-4 bg-gray-200 rounded-lg shadow-inner text-md" required placeholder="Jelszó">
              <input type="submit" value="Bejelentkezés" class="w-full py-4 px-6 text-md font-normal rounded-lg text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none cursor-pointer" />
              <div class="flex items-center self-center w-full">
                <router-link class="text-indigo-600 hover:text-indigo-800" :to="{ name: 'ForgottPass', params: {} }">Elfelejtett jelszó</router-link>
              </div>
            </div>
<!--
            <div class="notification is-danger">
              <button class="delete" @click="closeNotification"></button>
              {{message}}
            </div>
-->
          </form>
        </div>
      </div>
    </section>
    <img class="absolute top-0 left-0 hero-map" src="@/assets/svg/map-hero.svg" alt="">
  </div>

</template>

<script>

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data () {
    return {
      showModal: false,
      isLoad: false,
      email: '',
      password: '',
      error: false,
      message: ''
    }
  },
  methods: {
    async login () {
      this.isLoad = true

      try {
        const val = await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        // console.log(val)
        this.$router.replace({ name: 'Dashboard' })
      } catch (err) {
        this.error = true
        this.message = err.message
        console.log(err)
      }

      this.isLoad = false
    },
    closeNotification () {
      this.error = false
    }
  }
}
</script>

<style lang="scss">

</style>
